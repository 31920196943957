import { Environment } from '.';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment: Environment = {
  apiUrl: 'https://dev.brand-sync.com/api/v1/',
  GACode: 'UA-88381722-2',
  hmr: false,
  production: true,
  publicApiUrl: 'https://dev.brand-sync.com/api/public/v1/',
  storeDevtools: false,
//  sessionExpireTime: 1000 * 60 * 20,

  /* for BRAN-1553 
  zendesk: 'http://help.brand-sync.com',
  zendeskFta: 'http://fta.brand-sync.com',
  zendeskDha: '',
  zendeskHost: 'https://brandsync.zendesk.com',
  zendeskFtaRecipient: '',
  emailDhaCompany: '',
  */
  //showBubbles: false,
  dhaCompanyId: 'lxWOpa6zWTM',
  tatmeenCompanyId: 'HI44z67IgAe',
  sentryDSN: 'https://c9325eb062844c3dadaad4c7ac594c2b@sentry.gs1ae.org/5',
  sentryENV: 'dev',
  sentryTraceRate: 1.0,
  fta: {
    enabledHiddenAttributes: true,
    attributesToHide: [
      'isThisProductAPack',
      'numberOfUnitsPerPack',
      'childTradeItemgtin',
      'netContentOfIndividualUnit',
    ],
    defaultValuesForAttrs: {
      isThisProductAPack: 'No',
    }
  },
  enabledDirectDownload: false,
      knowledgeBaseUrls: {
        dha: 'https://gs1uae--devx.sandbox.my.site.com/DHA',
        fta: 'https://gs1uae--devx.sandbox.my.site.com/FTA',
        healthcare: 'https://gs1uae--devx.sandbox.my.site.com/healthcare',
        retail: 'https://gs1uae--devx.sandbox.my.site.com/retail',
        ftaProvider: {
            productImageRequirements: 'https://gs1uae--devx.sandbox.my.site.com/FTA/s/article/What-are-the-product-image-requirements',
            goodAndBadImageExamples: 'https://gs1uae--devx.sandbox.my.site.com/FTA/s/article/What-are-examples-of-good-and-bad-product-images',
            contactSupport: 'https://gs1uae--devx.sandbox.my.site.com/FTA/s/contactsupport',
	},
      },
      uaePassUrl: {
	      url: 'https://dev-id.uaepass.ae/idshub/authorize?response_type=code',
	      client_id: 'brandsync_web_dev',
	      scope: 'scope=urn:uae:digitalid:profile:general',
	      state: null,
	      redirect_uri: 'https://dev.brand-sync.com/login',
	      acr_values: 'urn:safelayer:tws:policies:authentication:level:low'
      },
      uaePassLogoutUrl: 'https://dev-id.uaepass.ae/idshub/logout?redirect_uri=https://dev.brand-sync.com/login',
};
